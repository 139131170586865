<template>
  <div class="circle-container">
    <div class="nav">
      <router-link to="/recommendcircle">推荐</router-link>
      <router-link to="/followcircle">关注</router-link>
      <router-link to="/circlecircle">学术圈</router-link>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.circle-container {
  width: 1440px;
  margin: 70px auto;
  .nav {
    width: 1040px;
    height: 32px;
    border-bottom: 1px solid #e6e6e6;
    a {
      width: 160px;
      display: inline-block;
      text-align: center;
      line-height: 24px;
      color: #333;
      &.router-link-exact-active::after {
        content: "";
        display: block;
        width: 160px;
        height: 8px;
        background-image: url("~@/assets/imgs/xz.png");
        background-size: 100% 100%;
      }
    }
  }
  ::v-deep .router-link-exact-active {
    color: #00A4FF !important;
  }
}
</style>
